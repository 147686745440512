import React, { useState, useEffect } from "react";
import ServicesModal from "./services-modal";
import {
  FaCut,
  FaFacebook,
  FaAt,
  FaMapMarked,
  FaBars,
  FaLink,
  FaInstagram,
} from "react-icons/fa";
import Logo from "../../images/logo.png";
import Kelly from "../../images/kelly_new.jpg";
import Expertise from "../../images/thumbnail_expertise-logo.jpg";
import "../navbar/desktop-nav.module.scss";
import "../layout/layout.scss";
import "../layout/page-layout.module.scss";

import indexStyles from "./landing-page.module.scss";

import SEO from "../seo";

const LandingPage = () => {
  // ! State
  const [showMenu, setShowMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(null);
  const [showServicesModal, setShowServicesModal] = useState(false);

  // ! Scroll functions
  const getScrollPosition = () => {
    const scrollPosition = window.scrollY;

    setScrollPosition(scrollPosition);
  };

  // ! Mobile menu button
  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  // ! Services modal

  const toggleServicesModal = () => {
    setShowServicesModal(!showServicesModal);
  };

  // ! Navbar links
  const renderNavLinks = () => {
    const linkData = [
      { title: "Home", to: "#" },
      { title: "About", to: "#about" },
      { title: "Gallery", to: "#gallery" },
      { title: "Meet the Owner", to: "#meetTheOwner" },
    ];

    return linkData.map((link) => (
      <li key={link.title} onClick={() => setShowMenu(false)}>
        <a href={link.to}>{link.title}</a>
      </li>
    ));
  };

  // ! On mount function
  useEffect(() => {
    window.addEventListener("scroll", getScrollPosition);

    return () => {
      window.removeEventListener("scroll", getScrollPosition);
    };
  });

  return (
    <>
      <SEO title="Home" />
      <div className={indexStyles.landingPage}>
        <nav
          className={`${indexStyles.navbar} ${indexStyles.navbarWithBackground}`}
        >
          <ul className={`${showMenu ? indexStyles.showMobile : null}`}>
            {renderNavLinks()}
          </ul>
          <button
            className={indexStyles.navbarMenuIcon}
            onClick={handleMenuClick}
          >
            Menu <FaBars />
          </button>
        </nav>

        <header className={indexStyles.header}>
          <div className={indexStyles.headerContent}>
            <img src={Logo} alt="logo" />
            <p>
              349 13th Avenue NE • Minneapolis <br /> (612) 315-5985
            </p>
          </div>
        </header>

        <p className={indexStyles.hours}>
          Tuesday 9-7 • Wednesday 9-7 • Thursday 9-7 • Friday 9-5 • Saturday 9-2
          • Sunday & Monday Closed
          {/* <br /> <br />
          <small>
            Kelly cuts hair in NE Mpls on Thursdays from noon to 6pm.
          </small>
          <br />
          <small>
            Kelly cuts hair in Stillwater on Wednesdays, Fridays and Saturdays
            by appointment or walk-in.{" "}
          </small> */}
        </p>

        <section className={indexStyles.aboutSection}>
          <div>
            <a className={indexStyles.block} href="https://votemnbest.com">
              <div className={indexStyles.badge}></div>
              <h2>Click Here</h2>
            </a>
          </div>
          <div id="about" className={indexStyles.about}>
            <h2>
              We are a traditional barbershop located in Northeast Minneapolis
              Arts’ District.
            </h2>
            <h3>
              Owner Kelly Sharp also operates from Jim’s Barber Shop in historic
              Stillwater. Book a NE Mpls appointment with Kelly with the
              scheduler button; appointments and walk-ins are available in
              Stillwater.
            </h3>

            <a
              href={
                "https://square.site/book/3M55RFGDYG65V/the-barber-sharp-minneapolis-mn"
              }
            >
              <button>Book an appointment today</button>
            </a>
            <button onClick={toggleServicesModal}>
              The Gentleman's Experience
            </button>
          </div>
        </section>

        {showServicesModal && (
          <ServicesModal toggleServicesModal={toggleServicesModal} />
        )}

        <section id="gallery" className={indexStyles.gallery}>
          <h2>Gallery</h2>
          <div className={indexStyles.galleryImageGrid}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </section>
        <section id="barber-gallery" className={indexStyles.gallery}>
          <h2>Our Staff</h2>
          <div className={indexStyles.galleryBarberImageGrid}>
            <span>
              <div></div>
              <p>Chelsie</p>
            </span>
            <span>
              <div></div>
              <p>Jesse</p>
            </span>
            <span>
              <div></div>
              <p>Steph</p>
            </span>
            <span>
              <div></div>
              <p>Dustin</p>
            </span>
            <span>
              <div></div>
              <p style={{ textAlign: "center" }}>
                Kelly, Proprietor
                {/* <br />
                <small>
                  Kelly also cuts hair from Jim’s Barber Shop located at 117
                  Churchill Street W in Historic Stillwater (612) 961-5280
                </small> */}
              </p>
            </span>
          </div>
        </section>

        <div className={indexStyles.divider}>
          <p>
            Getting married? Bring your groomsmen in for a shave party! Call to
            make an appointment.
          </p>
        </div>

        <section id="meetTheOwner" className={indexStyles.meetTheOwner}>
          <img
            src={Kelly}
            className={indexStyles.meetTheOwnerImg}
            alt="Barbershop Owner"
          />
          <div className={indexStyles.meetTheOwnerText}>
            <h2>Meet the Owner</h2>
            <p>
              After a long career in real estate and the downturn of the market,
              Kelly decided to go back to school to learn how to cut hair, a
              craft she has always been passionate about. She chose barbering
              for several reasons: to specialize in haircutting, facial
              grooming, traditional shaves, and most importantly to preserve a
              tradition that is slowly fading into a bygone era. Kelly is most
              passionate about listening and understanding what each client is
              looking to achieve in grooming, and connecting with the community
              to bring people together for the greater good. She opened the
              Barber Sharp in 2010.
            </p>
            <p>
              The Barber Sharp was named one of the Best Barbershops in
              Minneapolis for the second year in a row!
            </p>
            <p>
              <a
                href={
                  "https://www.expertise.com/mn/minneapolis/barbershops#provider1"
                }
              >
                Click here{" "}
              </a>
              to read more.
            </p>
            <a
              href={
                "https://www.expertise.com/mn/minneapolis/barbershops#provider1"
              }
            >
              <img src={Expertise} />
            </a>
          </div>
        </section>

        <footer className={indexStyles.footer}>
          <h3>The Barber Sharp</h3>
          <p>Bringing people together for the greater good</p>
          <div className={indexStyles.footerSocial}>
            <a
              href="https://www.facebook.com/thebarbersharpMPLS"
              target="_blank"
            >
              <FaFacebook />
            </a>
            <a href="https://www.instagram.com/thebarbersharp/" target="_blank">
              <FaInstagram />
            </a>
            <a
              href="mailto:thebarbersharp@gmail.com?subject=Inquiry from website"
              target="_blank"
            >
              <FaAt />
            </a>
            <a
              href="https://www.google.com/maps/place/349+13th+Ave+NE,+Minneapolis,+MN+55413/@45.0010876,-93.2642439,17z/data=!3m1!4b1!4m2!3m1!1s0x52b32d886857a7e3:0x68028c8e113e4058"
              target="_blank"
            >
              <FaMapMarked />
            </a>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;
