import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {
  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, user-scalable=no" />
      <meta
        name="title"
        content="The Barber Sharp was named one of the Best barber shops in Minneapolis"
      />
      <meta
        name="description"
        content="Need a haircut from an amazing barber? Come to the barber sharp. One of the best barber shops in Minneapolis"
      />
      <title>{`The Barbersharp | Home`}</title>
    </Helmet>
  );
};

export default SEO;
